/* eslint-disable */
/* Warning: this file is autogerated, any changes would be lost */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import WpAcfLandingTitleHeaderBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfTextActionBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfImageBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfTextActionButtonImageBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfIconTextBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfSingleProjectBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfAppStoreInfoBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpCoreParagraphBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'



const Blocks = ({ blocks = [], previewUUID }) => {
  return (
    <>
      {useMemo(() => {
        
        return blocks
      }, [blocks, previewUUID]).map((block, i) => {
        const children = block.innerBlocks ? <Blocks blocks={block.innerBlocks} previewUUID={previewUUID} /> : null;

        if (block.__typename === 'WpAcfLandingTitleHeaderBlock') {
            return <WpAcfLandingTitleHeaderBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfTextActionBlock') {
            return <WpAcfTextActionBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfImageBlock') {
            return <WpAcfImageBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfTextActionButtonImageBlock') {
            return <WpAcfTextActionButtonImageBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfIconTextBlock') {
            return <WpAcfIconTextBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfSingleProjectBlock') {
            return <WpAcfSingleProjectBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfAppStoreInfoBlock') {
            return <WpAcfAppStoreInfoBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpCoreParagraphBlock') {
            return <WpCoreParagraphBlock {...block} children={children} key={i} />
          }

        return null;
      })}
    </>
  );
};

export default ({ previewUUID }) => {

  const { wpPage: { blocks } } = useStaticQuery(graphql`
    fragment Blocks137 on WpBlock { __typename ...ThemeWordpressGutenbergUnknownBlock  }
    query {
      wpPage(id: {eq: "137"}) {
        blocks {
          ...Blocks137
        }
      }
    }`)

  return <Blocks blocks={blocks} previewUUID={previewUUID} />
}
